/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 124번째 로그에서는 Microsoft Edge, 윈도우 11 발표에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "Microsoft Edge 웹 브라우저"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/tree-style-tab/"
  }, "Tree Style Tab – Get this Extension for 🦊 Firefox")), "\n"), "\n", React.createElement(_components.h3, null, "윈도우 11 발표"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.itworld.co.kr/news/199154"
  }, "윈도우 11에서 처음 등장하는 새 기능 11가지 집중 조명 - ITWorld Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://byline.network/2021/06/25-149/"
  }, "안드로이드 앱은 어떻게 윈도우 11에서 실행될까 - Byline Network")), "\n"), "\n", React.createElement(_components.h3, null, "tailscale VPN 가격정책 변경"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tailscale.com/blog/2021-06-new-pricing/"
  }, "New Pricing · Tailscale")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
